import React, { useEffect, useState } from 'react'
import agent from 'api/agent'
import { showErrorToast, showSuccessToast } from 'utils/customToaster'
import { FileUploadResult } from 'models/common/fileUploadResult'
import FileDropzoneWidget from '../FileDropzoneWidget'
import BeatLoader from 'react-spinners/BeatLoader'

const FileUploadWidget: React.FC<{
  setFileUploadResult?: any
  fileUploadResult?: FileUploadResult,
  setFileMissingErrorMessage?: any
}> = ({ setFileUploadResult, fileUploadResult, setFileMissingErrorMessage }) => {
  const [file, setFile] = useState<Blob[]>(null)
  const [isLoading, setIsLoading] = useState(false)

  const addFile = (fileToUpload: Blob[]) => {
    setIsLoading(true)
    setFileMissingErrorMessage("")
    agent.File.uploadFile(fileToUpload[0])
      .then((fileUploadResult: FileUploadResult) => {
        if (fileUploadResult) {
          setFileUploadResult(fileUploadResult)
          showSuccessToast('File uploaded!')
        } else {
          showErrorToast('An error occured while uploading your file')
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          showErrorToast('File already exists!')
        } else {
          showErrorToast('An error occured while uploading your file')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteFile = () => {
    setIsLoading(true)
    agent.File.deleteFile(fileUploadResult?.url)
      .then(() => {
        showSuccessToast(`The uploaded file has been removed`)
        setFileUploadResult(null)
      })
      .catch(() => showErrorToast(`An error occured while uploading your file`))
      .finally(() => {
        setIsLoading(false)
        setFile(null)
      })
  }

  const files = file?.map((file: any) => (
    <a onClick={deleteFile} key={file.path}>
      <li>
        {file.path}{' '}
        <span style={{ fontSize: '20px', cursor: 'pointer' }}>&times;</span>
      </li>
    </a>
  ))

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <BeatLoader color="#FF2D55" />
        </div>
      ) : file && fileUploadResult ? (
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">{files}</div>
          </div>
        </div>
      ) : (
        <FileDropzoneWidget setFile={setFile} addFile={addFile} />
      )}
    </>
  )
}

export default FileUploadWidget
