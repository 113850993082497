import React from 'react'
import { Link } from 'react-router-dom'

class WhyUs extends React.Component {
  render() {
    return (
      <section className="why-choose-us">
        <div className="row m-0">
          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-headphone-alt-1"></i>
                    </div>
                    <h3>Professional recognition</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-network-tower"></i>
                    </div>
                    <h3>Opportunity for networking</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-users-social"></i>
                    </div>
                    <h3>New business relationships</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="content">
                    <div className="icon">
                      <i className="icofont-wink-smile"></i>
                    </div>
                    <h3>More than 100 professional awards</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="slideshow">
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
        </ul>
      </section>
    )
  }
}

export default WhyUs
