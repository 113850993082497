import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainBanner from "../AwardDetails/MainBanner";
import Footer from "../Common/Footer";
import "photoswipe/dist/photoswipe.css";
import { IBusinessEvent } from "models/businessEvent/businessEvent";
import agent from "api/agent";
import Sponsors from "components/Home/Sponsors";
import NomineeVotingGrid from "components/Voting/NomineeVotingGrid";
import ApplyForm from "components/Common/ApplyForm";

const AwardDetails: React.FC = () => {
  const { awardsId } = useParams();

  const [businessEvent, setBusinessEvent] = useState<IBusinessEvent>(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  useEffect(() => {
    agent.BusinessEvent.getById(awardsId)
      .then((selectedBusinessEvent) => {
        setBusinessEvent(selectedBusinessEvent);
        setStartDate(new Date(selectedBusinessEvent.startDate));
        setEndDate(new Date(selectedBusinessEvent.endDate));
      })
      .catch(() => {
        setBusinessEvent(null);
        setStartDate(null);
        setEndDate(null);
      });
  }, []);

  return (
    <>
      {businessEvent ? (
        <>
          <MainBanner
            title={businessEvent.name}
            location={businessEvent.location}
            startDate={startDate}
            endDate={endDate}
            coverImageLink={businessEvent.coverImageLink}
          />

          <div className="container section-min-height">
            <div className="row ">
              <div className="col-lg-12">
                <p
                  style={{
                    direction: "ltr",
                    textAlign: "justify",
                    paddingTop: "30px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: businessEvent.descriptionInHtml,
                  }}
                >
                  {/* {businessEvent.description} */}
                </p>
              </div>
              <div className="col-lg-12">
                <section
                  style={{
                    direction: "ltr",
                    paddingTop: "30px",
                    paddingBottom: "50px",
                  }}
                >
                  {businessEvent.nominees.length > 0 &&
                    businessEvent.areNomineesEnabled && (
                      <>
                        <h2
                          style={{ textAlign: "center", paddingBottom: "20px" }}
                        >
                          Nominees
                        </h2>
                        <NomineeVotingGrid businessEvent={businessEvent} />
                      </>
                    )}
                  {!businessEvent.isVotingEnabled && <ApplyForm />}
                  {businessEvent.sponsors.length > 0 && (
                    <Sponsors sponsors={businessEvent.sponsors} />
                  )}
                </section>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <MainBanner title={"-"} location="-" start={null} end={null} />
          <div
            className="container section-min-height"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="row">
              <div className="col-lg-12 text-center custom-container">
                <p>Loading..</p>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default AwardDetails;
