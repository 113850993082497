import React from 'react'
import MainBanner from '../Awards/MainBanner'
import GoTop from '../Shared/GoTop'
import Footer from '../Common/Footer'
import lax from 'lax.js'
import ApplyForm from '../Common/ApplyForm'
import EventSchedules from 'components/Home/EventSchedules'
import AwardsText from 'components/Awards/AwardsText'

class Awards extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false,
    )

    lax.update(window.scrollY)
  }
  render() {
    return (
      <React.Fragment>
        <MainBanner />
        <AwardsText />
        <EventSchedules />
        <ApplyForm />
        <Footer />
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    )
  }
}

export default Awards
