import "./assets/css/bootstrap.min.css";
import "./assets/css/icofont.min.css";
import "./assets/css/animate.min.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
import "./assets/css/style.css";
import "./assets/css/rtl.css";
import "./assets/css/responsive.css";

import React, { useEffect } from "react";
import AppRouter from "./Routes";
import TagManager from "react-gtm-module";

const App: React.FC = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CODE });
  });

  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
