import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainBanner from '../NomineeDetails/MainBanner'
import Footer from '../Common/Footer'
import { INominee } from 'models/nominee/nominee'
import agent from 'api/agent'

const NomineeDetails: React.FC = () => {
  const { nomineeId } = useParams()
  const [selectedNominee, setSelectedNominee] = useState<INominee>(null)

  useEffect(() => {
    agent.Nominee.getById(nomineeId).then((nominee) => {
        setSelectedNominee(nominee)
    })
  }, [])

  return (
    <>
      {selectedNominee ? (
        <>
          <MainBanner
            title={selectedNominee.name}
            businessEventId={selectedNominee.businessEvent.id}
            businessEventName={selectedNominee.businessEvent.name}
          />
          <div className="col-lg-12">
            <section
              style={{
                direction: 'ltr',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
              className="section-min-height"
            >
              <div className="container">
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      paddingBottom: '20px',
                      width: '80%',
                      height: '80%',
                      maxHeight: '400px',
                      objectFit: 'contain',
                    }}
                    src={selectedNominee.image}
                    alt=""
                  />
                  <p
                    style={{
                      direction: 'ltr',
                      textAlign: 'center',
                      width: '80%',
                    }}
                  >
                    {selectedNominee.companyName}
                  </p>
                  <p
                    style={{
                      direction: 'ltr',
                      textAlign: 'center',
                      width: '80%',
                    }}
                  >
                    {selectedNominee.role}
                  </p>
                  <p
                    style={{
                      direction: 'ltr',
                      textAlign: 'justify',
                      width: '80%',
                    }}
                  >
                    {selectedNominee.description}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <>
          <MainBanner
            title="-"
            businessEventId="-"
            businessEventName="-"
          />
          <div className="container section-min-height" style={{display: 'flex', justifyContent: 'center'}}>
            <div className="row">
              <div className="col-lg-12 text-center custom-container">
                <p>Loading..</p>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  )
}

export default NomineeDetails
