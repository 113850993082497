import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NewsLetterSwal = withReactContent(Swal);

const ContactForm: React.FC = () => {
  const successAlertContent = () => {
    NewsLetterSwal.fire({
      title: "Contact was successful",
      text: "Thank you for contacting with us",
      icon: "success",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const errorAlertContent = () => {
    NewsLetterSwal.fire({
      title: "Contact was unsuccessful",
      text: "Try again later",
      icon: "error",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <section
        className="contact-area"
        style={{ direction: "ltr", paddingBottom: "120px" }}
      >
        <div className="container">
          <div className="row h-100 align-items-center contact-form">
            <div
              className="col-lg-12"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  emailjs
                    .send(
                      "service_6y0278b",
                      "template_1ej1e4i",
                      {
                        name: "Events & Awards",
                        from_name: `${values.name}`,
                        reply_to: values.email,
                        subject: "Getting in touch",
                        message: values.message,
                        site_name: "eventsandawards.com",
                        to_email: "info@eventsandawards.com",
                        from_email: "postmaster@globalhighlightcm.com",
                      },
                      "user_LrB06JmtNSOzWeUdvPyEj"
                    )
                    .then(() => {
                      setSubmitting(false);
                      successAlertContent();
                      resetForm();
                    })
                    .catch((e) => {
                      errorAlertContent();
                      setSubmitting(false);
                    });
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Name is required!"),
                  email: Yup.string()
                    .required("Email is required!")
                    .email("Invalid email!"),
                  message: Yup.string().required("Message is required!"),
                })}
              >
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                  isSubmitting,
                  errors,
                  touched,
                }) => (
                  <>
                    {isSubmitting ? (
                      <p>Sending message...</p>
                    ) : (
                      <form noValidate onSubmit={handleSubmit}>
                        <legend
                          style={{ textAlign: "center", fontSize: "26px" }}
                        >
                          Contact us
                        </legend>
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="name">Name*</label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                required={true}
                                data-error="Please enter your name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">E-mail*</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                required={true}
                                data-error="Please enter your email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              <label htmlFor="name">Message*</label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols={30}
                                rows={4}
                                required={true}
                                data-error="Write your message"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.message}
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>

                          {/* <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            id="gdpr"
                            type="checkbox"
                            name="gdpr"
                            required={true}
                            checked={state.formFields.gdpr}
                            data-error="Write your message"
                            onChange={inputChangeHandler}
                            style={{ marginRight: "10px" }}
                          />
                          <label htmlFor="name" style={{ paddingRight: "5px" }}>
                            Accept our
                          </label>
                          <a
                            href="https://google.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div> */}

                          <div
                            className="col-lg-12 col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              paddingTop: "20px",
                            }}
                          >
                            <button type="submit" className="btn btn-primary">
                              Send
                            </button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </form>
                    )}
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
