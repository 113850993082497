import React from 'react'
import ReferenceDetails from 'components/ReferenceDetails/ReferenceDetails'

class Reference extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ReferenceDetails />
      </React.Fragment>
    )
  }
}

export default Reference
