import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import agent from 'api/agent'
import useIPAddress from 'utils/customHooks/useIPAddress'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ICurrentVotingBusinessEvent } from 'models/businessEvent/businessEvent'

const NomineeVotingGrid: React.FC<{
  businessEvent: ICurrentVotingBusinessEvent
}> = ({ businessEvent }) => {
  const ip = useIPAddress()
  const swal = withReactContent(Swal)
  const [selectedNomineeId, setSelectedNomineeId] = useState<string>(null)

  const successAlertContent = () => {
    swal.fire({
      title: 'Vote registered',
      text: 'Thank you',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    })
  }

  const errorAlertContent = (errorMessage: string) => {
    swal.fire({
      title: 'Vote was not registered',
      text: errorMessage,
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    })
  }

  const isIpAddressBlocked = (): boolean => {
    const { blockedIpAddressesForVoting } = businessEvent
    return blockedIpAddressesForVoting.some(
      (blockedIp) => blockedIp.ipAddress === ip,
    )
  }

  const voteOnNominee = () => {
    if (selectedNomineeId == null) {
      errorAlertContent('Please select one candidate')
      return
    }
    if (isIpAddressBlocked()) {
      errorAlertContent('You have already voted once')
      return
    }

    agent.Nominee.voteOnNominee(selectedNomineeId, ip)
      .then(() => {
        successAlertContent()
      })
      .catch((error) => {
        if (error && error.status === 403) {
          errorAlertContent('You have already voted once')
        } else {
          errorAlertContent(`Something went wrong please try again later`)
        }
      })
  }

  const handleCheckBoxClick = (nomineeId: string) => {
    setSelectedNomineeId(nomineeId)
  }

  const history = useHistory()

  const handleNavigation = (id) => {
    const url = `/nominee/${id}`
    history.push(url)
    window.scrollTo(0, 0)
  }

  return (
    <div className="container">
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {businessEvent.nominees.map((nominee, index) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={nominee.id}>
            <div
              className="single-speakers-box "
              onClick={() => handleNavigation(nominee.id)}
            >
              <div className="image-gallery-image-container">
                <img
                  style={{
                    cursor: 'pointer',
                    paddingBottom: '20px',
                    maxHeight: '100%',
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={nominee.image}
                />
              </div>

              <div className="speakers-content">
                <h3>{nominee.name}</h3>
                <p>{nominee.role}</p>
                <p>{nominee.companyName}</p>
                <span>Open</span>
              </div>
            </div>
            {businessEvent.isVotingEnabled && !isIpAddressBlocked() && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '20px',
                }}
              >
                <div key={nominee.id}>
                  <input
                    type="checkbox"
                    id={`box_${index}`}
                    checked={selectedNomineeId === nominee.id}
                    onChange={() => handleCheckBoxClick(nominee.id)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {businessEvent.isVotingEnabled && !isIpAddressBlocked() ? (
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <button
            type="submit"
            className="btn btn-primary"
            onClick={voteOnNominee}
            style={{ width: '100px' }}
          >
            Send
          </button>
        </div>
      ) : (
        businessEvent.isVotingEnabled && (
          <p style={{ textAlign: 'center' }}>
            Your vote has been recorded. Thank you for participating!
          </p>
        )
      )}
    </div>
  )
}

export default NomineeVotingGrid
