import React from "react";

class MainBanner extends React.Component {
  render() {
    return (
      <div className="page-title-area item-bg1">
        <div className="container">
          <h1>Events</h1>
        </div>
      </div>
    );
  }
}

export default MainBanner;
