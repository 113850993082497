/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios'
import { showErrorToast } from 'utils/customToaster'
import { Paginated } from 'models/common/pagination'
import {
  IBusinessEvent,
  ICurrentVotingBusinessEvent,
} from 'models/businessEvent/businessEvent'
import { OrderByName } from 'models/common/orderByName'
import { INominee } from 'models/nominee/nominee'
import { IReference } from 'models/reference/reference'
import { ILessDetailedBusinessEvent } from 'models/businessEvent/lessDetailedBusinessEvent'
import { ILessDetailedNominee } from 'models/nominee/lessDetailedNominee'
import { IBusinessEventByName } from 'models/businessEvent/businessEventByName'
import { FileUploadResult } from 'models/common/fileUploadResult'
import { CreateAttendee } from 'models/attendee/createAttendee'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.response.use(undefined, (error) => {
  if (!error || !error.response) {
    return
  }

  if (error.message === 'Network Error' && !error.response) {
    showErrorToast('Server is unavailable!')
  }
  const { status, data, config, headers } = error.response
  if (status === 400) {
    showErrorToast(data.message)
  } else if (status === 404) {
    showErrorToast('No data available!')
  } else if (status === 406) {
    showErrorToast(data.message)
  } else if (status === 401) {
    showErrorToast('You have no permission to view this content')
  } else if (status === 500) {
    showErrorToast('')
  }

  throw error.response
})

const responseBody = (response: AxiosResponse) => response?.data

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body?: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  patch: (url: string, body?: {}) => axios.patch(url, body).then(responseBody),
  postFile: (url: string, file: Blob) => {
    const formData = new FormData()
    formData.append('file', file)
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody)
  },
  postFiles: (url: string, files: Blob[]) => {
    const formData = new FormData()
    files.forEach((file) => formData.append('files', file))
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody)
  },
}

const FileRequests = {
  uploadFile: (file: Blob): Promise<FileUploadResult> =>
    requests.postFile(`/File/AddFile/`, file),
  deleteFile: (fileUrl: string): Promise<any> =>
    requests.post(`/File/RemoveFile`, { fileUrl }),
}

const NomineeRequests = {
  getById: (nomineeId: string): Promise<INominee> =>
    requests.get(`/Nominee/GetNomineeById/${nomineeId}`),
  getFilteredPage: (
    pageNumber: number,
    pageSize: number,
    businessEventId?: string,
    nomineeName?: string,
    orderByName?: OrderByName,
  ): Promise<Paginated<ILessDetailedNominee>> =>
    requests.post(`/Nominee/GetFilteredFrontendNominees`, {
      pageNumber: pageNumber,
      pageSize: pageSize,
      businessEventId: businessEventId,
      nomineeName: nomineeName,
      orderByName: orderByName,
    }),
  voteOnNominee: (nomineeId: string, IpAddressToBlock: string): Promise<any> =>
    requests.post(`/Nominee/VoteOnNominee`, {
      nomineeId: nomineeId,
      IpAddressToBlock: IpAddressToBlock,
    }),
}

const AttendeeRequests = {
  create: (attendee: CreateAttendee): Promise<any> =>
    requests.post('/Attendee/CreateAttendee', attendee),
}

const ReferenceRequests = {
  getById: (id: string): Promise<IReference> =>
    requests.get(`/Reference/GetReferenceById/${id}`),
  getFilteredPage: (
    pageNumber: number,
    pageSize: number,
    name?: string,
  ): Promise<Paginated<IReference>> =>
    requests.post(`/Reference/GetFilteredReferences`, {
      pageNumber: pageNumber,
      pageSize: pageSize,
      name: name,
    }),
}

const BusinessEventRequests = {
  getById: (businessEventId: string): Promise<IBusinessEvent> =>
    requests.get(`/BusinessEvent/GetBusinessEventById/${businessEventId}`),
  getAvailable: (): Promise<IBusinessEventByName[]> =>
    requests.get(`/BusinessEvent/GetAvailableBusinessEvents`),
  getCurrentVotingEvent: (): Promise<ICurrentVotingBusinessEvent> =>
    requests.get(`/BusinessEvent/GetCurrentVotingEvent`),
  getFilteredPage: (
    pageNumber: number,
    pageSize: number,
    fromDate?: Date,
    toDate?: Date,
    businessEventName?: string,
    orderByName?: OrderByName,
  ): Promise<Paginated<ILessDetailedBusinessEvent>> =>
    requests.post(`/BusinessEvent/GetFilteredBusinessEvents`, {
      pageNumber: pageNumber,
      pageSize: pageSize,
      businessEventName: businessEventName,
      fromDate: fromDate,
      toDate: toDate,
      orderBy: orderByName,
    }),
}

export default {
  File: FileRequests,
  Nominee: NomineeRequests,
  Reference: ReferenceRequests,
  Attendee: AttendeeRequests,
  BusinessEvent: BusinessEventRequests,
}
