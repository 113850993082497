import React from 'react'
import 'isomorphic-fetch'
import Footer from '../Common/Footer'
import ContactForm from 'components/Contact/ContactForm'

class Contact extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg5">
          <div className="container">
            <h1>Contact Us</h1>
          </div>
        </div>

        <section className="contact-area ptb-120" style={{ direction: 'ltr' }}>
          <div className="container">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="contact-box">
                <div className="content contact-box-content">
                  <img
                    src="/images/somogyi_image.webp"
                    style={{ paddingBottom: '30px', paddingTop: '30px' }}
                  />
                  <h4>Péter Somogyi</h4>
                  <p>Hungarian Business Awards</p>
                  <p>European Milestone Business Awards producer</p>
                  <a
                    href="https://www.linkedin.com/in/peter-somogyi-06b7a521/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>
                </div>
                <div className="content contact-box-content">
                  <h4>Mobile</h4>
                  <p>(+36) 30 207 5247</p>
                </div>
                <div className="content contact-box-content">
                  <h4>E-mail</h4>
                  <p>peter@eventsandawards.com</p>
                </div>
                <div className="content contact-box-content">
                  <h4>Location</h4>
                  <p>EVENTS & AWARDS Ltd.</p>
                  <p>71 - 75 Shelton Street, Covent Garden, London WC2H 9JO</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactForm />
        <Footer />
      </React.Fragment>
    )
  }
}

export default Contact
