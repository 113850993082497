/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'

const dropzoneStyles = {
  border: 'dashed 3px',
  borderColor: '#eee',
  borderRadius: '5px',
  paddingTop: '30px',
  textAlign: 'center' as const,
  height: '100px',
}

const dropzoneActive = {
  borderColor: 'green',
}

const acceptObject: Accept = {
  document: ['application/pdf'],
}

const FileDropzoneWidget: React.FC<{
  setFile: (file: Blob[]) => void
  addFile: (file: Blob[]) => void
}> = ({ setFile, addFile }) => {
  const onDrop = useCallback(
    (fileToSet) => {
      const fileToUpload =  fileToSet.map((file: Blob) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    )
      setFile(fileToUpload)
      addFile(fileToUpload)
    },
    [setFile],
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: acceptObject,
    maxFiles: 1,
  })


  return (
    <div>
      <div
        {...getRootProps()}
        style={
          isDragActive
            ? { ...dropzoneStyles, ...dropzoneActive }
            : dropzoneStyles
        }
      >
        <input {...getInputProps()} />
        <p>Upload your file</p>
      </div>
    </div>
  )
}

export default FileDropzoneWidget
