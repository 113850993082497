import React, { useEffect } from "react";
import lax from "lax.js";
import { useHistory } from "react-router-dom";

const About = () => {
  useEffect(() => {
    lax.setup();

    const handleScroll = () => {
      lax.update(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll);

    lax.update(window.scrollY);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const history = useHistory();

  const handleNavigation = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <section className="about-area ptb-120 bg-image">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-6">
            <div className="about-content" style={{ direction: "ltr" }}>
              <span>Join The Event</span>
              <h2>
                Welcome to the <b>dawn of</b> recognition and excellence!
              </h2>
              <p>
                Step into our world where achievement knows no bounds and
                success is celebrated with prestige. Introducing our brand-new
                business awards, where greatness is acknowledged and
                accomplishments are honored.
              </p>
              <h3>Are you ready to stand in the spotlight?</h3>
              <p>
                Whether you're nominating yourself or someone else, this is the
                platform to shine a light on the remarkable individuals and
                businesses that define success in our dynamic world. It's time
                to celebrate innovation, dedication and extraordinary business
                achievements.
              </p>
              <p>
                Join us on this exciting journey of recognition, where the best
                and brightest are acknowledged for their outstanding
                contributions. Nominate now and be a part of the elite circle of
                excellence. Your success deserves to be celebrated – let the
                accolades begin!
              </p>

              <button
                onClick={() => handleNavigation("/events")}
                className="btn btn-primary"
                style={{ margin: "5px" }}
              >
                Events
              </button>
              <button
                onClick={() => handleNavigation("/contact")}
                className="btn btn-secondary"
              >
                Contact
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-image">
              <img
                src={require("../../assets/images/home-page-intro-1.webp")}
                className="about-img1"
                alt="about"
              />

              <img
                src={require("../../assets/images/home-page-intro-2.webp")}
                className="about-img2"
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
