import React from 'react'
import { Link } from 'react-router-dom'

class MainBanner extends React.Component {
  render() {
    const { title, businessEventId, businessEventName } = this.props
    return (
      <div className="page-title-area item-bg5">
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>
    )
  }
}

export default MainBanner
