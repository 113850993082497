import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel3'
import HomeHeader from './Header'
import agent from 'api/agent'
import { OrderByName } from 'models/common/orderByName'
import DefaultBanner from './DefaultHeader'

const MainBanner: React.FC = () => {
  const owlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: false,
    autoplay: true,
    items: 1,
    center: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
    navText: [
      "<i class='icofont-rounded-left'></i>",
      "<i class='icofont-rounded-right'></i>",
    ],
  }
  
  const [businessEvents, setBusinessEvents] = useState([])
  const queryInput = {
    pageNumber: 0,
    pageSize: 2,
    fromDate: new Date(),
    toDate: null,
    businessEventName: null,
    orderByName: OrderByName.OrderByDate,
  }

  useEffect(() => {
    agent.BusinessEvent.getFilteredPage(
      queryInput.pageNumber,
      queryInput.pageSize,
      queryInput.fromDate,
      queryInput.toDate,
      queryInput.businessEventName,
      queryInput.orderByName,
    ).then((events) => {
      setBusinessEvents(events.page)
    }).catch(() => (
      setBusinessEvents(null)
    ))
  }, [])

  return (
    <>
      {businessEvents?.length > 0 ? (
        <OwlCarousel className="owl-carousel owl-theme schedule-slides"
          {...owlOptions}
        >
          {businessEvents.map((businessEvent) => (
            <HomeHeader businessEvent={businessEvent} key={businessEvent.id} />
          ))}
        </OwlCarousel>
      ): (
        <DefaultBanner />
      )
    }
    </>
  )
}

export default MainBanner
