import React, { useEffect, useState } from "react";
import { areDatesEqual, formatDate, formatTime } from "utils/dateUtils";
import { useHistory } from "react-router-dom";
import { ILessDetailedBusinessEvent } from "models/businessEvent/lessDetailedBusinessEvent";
import { Link } from "react-router-dom";

const AwardCard: React.FC<{ businessEvent: ILessDetailedBusinessEvent }> = ({
  businessEvent,
}) => {
  const history = useHistory();
  const navigateTo = (path: string) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  const [isSmallWindowWidth, setIsSmallWindowWidth] = useState(
    window.innerWidth <= 768
  );

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallWindowWidth = window.innerWidth <= 768;
      if (newIsSmallWindowWidth !== isSmallWindowWidth) {
        setIsSmallWindowWidth(newIsSmallWindowWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallWindowWidth]);

  return (
    <div
      className="single-schedule"
      style={{ cursor: "pointer" }}
      onClick={() => navigateTo(`/event/${businessEvent.id}`)}
    >
      <div className="schedule-date">
        <div className="d-table">
          <div className="d-table-cell">
            <div style={{ direction: "ltr" }}>
              {!isSmallWindowWidth && (
                <>
                  <span>{formatDate(new Date(businessEvent.startDate))}</span>
                  {!areDatesEqual(
                    businessEvent.startDate,
                    businessEvent.endDate
                  ) && (
                    <span>
                      {" "}
                      - {formatDate(new Date(businessEvent.endDate))}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="schedule-content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="schedule-info"
            style={{
              minWidth: "150px",
              width: "fitContent",
              padding: "10px",
              maxWidth: "300px",
            }}
          >
            <h3 className="awards-card-title"> {businessEvent.name} </h3>
            <ul>
              <li>
                <div className="awards-card-title">
                  <i className="icofont-location-pin"></i>
                  {businessEvent.location}
                </div>
              </li>
            </ul>

            <ul>
              <li>
                {isSmallWindowWidth ? (
                  <div>
                    {" "}
                    <i className="icofont-wall-clock"></i>
                    <span>{formatDate(new Date(businessEvent.startDate))}</span>
                    {!businessEvent.areTheDatesOnTheSameDay && (
                      <span>
                        {" "}
                        - {formatDate(new Date(businessEvent.endDate))}
                      </span>
                    )}
                  </div>
                ) : (
                  <i className="icofont-wall-clock"></i>
                )}
                {formatTime(new Date(businessEvent.startDate))} -{" "}
                {formatTime(new Date(businessEvent.endDate))}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="schedule-desc">{businessEvent.description}</p>
          <Link to={`/event/${businessEvent.id}`} style={{ direction: "rtl" }}>
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AwardCard;
