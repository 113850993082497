import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Footer from "../Common/Footer";

class Faq extends React.Component {
  submitHandler = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg4">
          <div className="container">
            <h1>FAQ</h1>
            <span>Frequently Asked Questions</span>
          </div>
        </div>

        <section className="faq-area ptb-120" style={{ direction: "ltr" }}>
          <div className="container">
            <div className="faq-accordion">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      IN WHICH CATEGORIES CAN A COMPANY DIRECTOR/COMPANY BE
                      NOMINATED?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Applications for awards are normally understood to be for
                      your own sector. If the nomination is for a category other
                      than the core activity, please indicate this in the
                      comment box.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      CAN I NOMINATE INDIVIDUALS AND COMPANIES (CAN I NOMINATE
                      ANYONE, OR IS THERE A LIST?)
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Both company directors and companies can be nominated, but
                      there is no predetermination as to who can be a candidate.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      CAN I NOMINATE MYSELF AND/OR MY COMPANY?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, a company director can nominate both himself or
                      herself and his or her company for each professional,
                      sectoral award.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      CAN I NOMINATE MY COMPANY FOR TWO AWARDS AT THE SAME TIME?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You can do this, of course, by sending the nomination for
                      each person/company separately.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      IN WHAT FORM AND WHERE CAN I NOMINATE?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You can nominate on the main page or on the Awards page by
                      selecting the appropriate award category. You can make a
                      nomination for the Business Lifetime Achievement Award in
                      the Business Lifetime Achievement Awards section.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      HOW ARE THE AWARDS PRESENTED?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      In an exclusive award ceremony announced in advance on our
                      website.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Faq;
