import React, { useState, useEffect, useRef } from 'react'
import agent from 'api/agent'
import { useHistory } from 'react-router-dom'

const ReferencesComponent = ({ title, referencePage }) => {
  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    pageSize: 8,
    page: 0,
  })

  const background = () => {
    if (referencePage) {
      return ''
    } else {
      return 'golden-background-with-opacity'
    }
  }
  const history = useHistory()

  const handleNavigation = (referenceId) => {
    const url = `/reference/${referenceId}`
    history.push(url)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    fetchData()
  }, [data.page])

  const updateData = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const fetchData = async () => {
    try {
      updateData('loading', true)
      const reference = await agent.Reference.getFilteredPage(
        data.page,
        data.pageSize,
        null,
      )
      updateData('rowCount', reference.allCount)
      updateData('rows', reference.page)
      updateData('loading', false)
    } catch (error) {
      console.error('Error fetching data:')
    }
  }

  const onPageChange = (page) => {
    updateData('page', page)
  }

  const Pagination = () => {
    const pageCount = Math.ceil(data.rowCount / data.pageSize)

    return (
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {data.page > 0 && (
          <button
            className="btn btn-secondary"
            style={{ margin: 10, width: 'fit-content' }}
            onClick={() => onPageChange(data.page - 1)}
          >
            Previous page
          </button>
        )}
        {data.page < pageCount - 1 && (
          <button
            className="btn btn-secondary"
            style={{ margin: 10, width: 'fit-content' }}
            onClick={() => onPageChange(data.page + 1)}
          >
            Next page
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      {((data && data.rows.length > 0) || referencePage) && (
        <section
          className={`speakers-area-two section-min-height ptb-120 ${background()}`}
          style={{ direction: 'ltr' }}
        >
          <div className="container">
            <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
              <>
                {title && (
                  <h3 style={{ textAlign: 'center', paddingBottom: '10px' }}>
                    {title}
                  </h3>
                )}
                {data.rows.map((reference) => (
                  <div
                    className="col-lg-4 col-md-6"
                    key={reference.id}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    onClick={() => handleNavigation(reference.id)}
                  >
                    <div
                      className="single-speakers-box"
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div className="image-gallery-image-container">
                        <img
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            height: '100%',
                            maxHeight: '100%',
                            objectFit: 'cover',
                          }}
                          src={reference.thumbnailImage}
                          alt=""
                        />
                      </div>

                      <div
                        className="speakers-content d-flex flex-column justify-content-center"
                        style={{ height: '100%' }}
                      >
                        <h3
                          style={{
                            wordWrap: 'break-word',
                            margin: '0',
                            textTransform: 'uppercase',
                            padding: '5px',
                          }}
                        >
                          {reference.name.toUpperCase()}
                        </h3>
                        <span>Open</span>
                      </div>
                    </div>
                  </div>
                ))}
                <Pagination />
              </>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ReferencesComponent
