import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import logo from "../../assets/images/events_and_awards_logo.png";

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const history = useHistory();

  const navigateTo = (path) => {
    if (!collapsed) {
      setCollapsed(true);
    }
    history.push(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    const handleScroll = () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    };
    document.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <header id="header" className="header-area">
      <div id="navbar" className="elkevent-nav" style={{ direction: "ltr" }}>
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container">
            <a className="navbar-brand" to="/">
              <img
                style={{ maxWidth: "200px" }}
                src={logo}
                alt=" "
                onClick={() => navigateTo("/")}
              />
            </a>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <a onClick={() => navigateTo("/")} className="nav-link">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={() => navigateTo("/events")} className="nav-link">
                    Events
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => navigateTo("/references")}
                    className="nav-link"
                  >
                    References
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={() => navigateTo("/faq")} className="nav-link">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => navigateTo("/contact")}
                    className="nav-link"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default withRouter(Navigation);
