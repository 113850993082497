import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Navigation from "./components/Navigation/Navigation";
import References from "./components/pages/References";
import NotFound from "./components/pages/NotFound";
import Faq from "./components/pages/Faq";
import Contact from "./components/pages/Contact";
import Awards from "components/pages/Awards";
import Reference from "components/pages/Reference";
import AwardDetails from "components/AwardDetails/AwardDetails";
import NomineeDetails from "components/NomineeDetails/NomineeDetails";

// Conditionally render Navigation
const renderNavigation = () => {
  if (
    !(
      window.location.pathname === "/login" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/coming-soon" ||
      window.location.pathname === "/error-404"
    )
  ) {
    return <Navigation />;
  }
};

const AppRouter = () => {
  return (
    <Router>
      {renderNavigation()}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/events" exact component={Awards} />
        <Route path="/event/:awardsId" exact component={AwardDetails} />
        <Route path="/nominee/:nomineeId" exact component={NomineeDetails} />
        <Route path="/references" exact component={References} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/error-404" exact component={NotFound} />
        <Route path="/reference/:referenceId" exact component={Reference} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
