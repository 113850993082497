import React from 'react';

class DefaultBanner extends React.Component {
    render(){
        return (
            <div className="page-title-area item-bg2"/>
        );
    }
}
 
export default DefaultBanner;