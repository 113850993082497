import React, { useState, FC, useEffect } from 'react'
import 'yet-another-react-lightbox/styles.css'
import {
  Fullscreen,
  Thumbnails,
  Zoom,
} from 'yet-another-react-lightbox/plugins'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import Lightbox from 'yet-another-react-lightbox'

const ImageGallery = ({ images }) => {
  const [index, setIndex] = useState<number>(-1)

  const slides = images.map((image) => ({
    src: image.imageLink,
  }))

  // force direction change of the body, otherwise Lightbox does not work properly
  useEffect(() => {
    document.body.style.direction = "ltr";
  
    return () => {
      document.body.style.direction = "rtl";
    };
  }, []);


  return (
  <div>
      <Images
        data={slides}
        onClick={(currentIndex) => {
          setIndex(currentIndex)
        }}
      />

      <Lightbox
        plugins={[Fullscreen, Zoom, Thumbnails]}
        index={index}
        open={index >= 0}
        close={() => setIndex(-1)}
        slides={slides}
      />
    </div>
  )
}

export default ImageGallery

interface ImagesProps {
  data: {src: string}[]
  onClick: (index: number) => void
}

const Images: FC<ImagesProps> = (props) => {
  const { data, onClick } = props

  const handleClickImage = (index: number) => {
    onClick(index)
  }

  return (
    <div className="gallery-image-container">
      {data.map((slide, index) => (
        <div
          onClick={() => handleClickImage(index)}
          key={index}
          className="gallery-image"
        >
          <img src={slide.src} />
        </div>
      ))}
    </div>
  )
}
