import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MainBanner from '../ReferenceDetails/MainBanner'
import Footer from '../Common/Footer'
import ImageGallery from 'components/Common/ImageGallery'
import { IReference } from 'models/reference/reference'
import agent from 'api/agent'
import YouTube from 'react-youtube';

const ReferenceDetails: React.FC = () => {
  const { referenceId } = useParams()
  const [selectedReference, setSelectedReference] = useState<IReference>(null)

  useEffect(() => {
    agent.Reference.getById(referenceId).then((reference) => {
      setSelectedReference(reference)
    })
  }, [])

  return (
    <>
      {selectedReference ? (
        <>
          <MainBanner title={selectedReference.name} />
          <div className="container section-min-height">
            {
              selectedReference.youtubeLinkId && 
              <YouTube videoId={selectedReference.youtubeLinkId.toString()} style={{justifyContent: 'center', display: 'flex', paddingTop: '30px'}}/>
            }
         
            {selectedReference.images.length > 0 && (
              <ImageGallery images={selectedReference.images} />
            )}
          </div>
        </>
      ) : (
        <>
          <MainBanner title="-" />
          <div
            className="container section-min-height"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className="row">
              <div className="col-lg-12 text-center custom-container">
                <p>Loading..</p>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  )
}

export default ReferenceDetails
