import React from 'react'

class MainBanner extends React.Component {
  render() {
    return (
      <div className="page-title-area item-bg3">
        <div className="container">
          <h1>References</h1>
        </div>
      </div>
    )
  }
}

export default MainBanner
