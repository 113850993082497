import React, { useState } from 'react'
import Select from 'react-select'

const CustomSelect = ({ options, setSelectedOption, selectedOption }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const selectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      height: '50px',
      background: '#f1f1f1',
      boxShadow: 'unset',
      borderStyle: 'none',
    }),
  }

  const customTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    borderColor: '#eeeeee',
    border: isFocused ? '1px solid #FF2D55' : '1px solid ' + '#eeeeee',
    colors: {
      ...theme.colors,
      primary25: '#FF2D55',
      primary: '#FF2D55',
    },
    '&:hover': {
      borderColor: '#FF2D55',
    },
  })

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }

  return (
    <Select
      className="form-control-select"
      value={selectedOption}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      options={options}
      styles={selectStyles}
      theme={customTheme}
      required={true}
      placeholder={'Choose an event'}
    />
  )
}

export default CustomSelect
