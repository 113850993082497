import React from 'react'

const AwardsText: React.FC = () => {
  return (
    <div className="container">
      <div className="row" style={{textAlign: 'justify', padding: '40px', direction: 'ltr'}}>
        <p> Enter a realm where achievement knows no bounds and success is esteemed with prestige. Announcing our latest business awards, designed to recognize greatness and celebrate accomplishments. Whether you're nominating yourself or others, seize the opportunity to illuminate remarkable individuals and businesses shaping success. Come join us in honoring innovation, dedication, and exceptional achievements. Submit your nominations now and become part of an exclusive circle of excellence – your success deserves to be applauded!</p>
      </div>
    </div>
  )
}
export default AwardsText;