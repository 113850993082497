import React, { useEffect } from 'react'
import lax from 'lax.js'
import { ISponsor } from 'models/businessEvent/sponsor'

const Sponsors: React.FC<{ sponsors: ISponsor[] }> = ({ sponsors }) => {
  useEffect(() => {
    lax.setup()

    const handleScroll = () => {
      lax.update(window.scrollY)
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className="partner-area ptb-120">
      <div className="container">
        <div className="section-title">
          <span>Check Who Makes This Event Possible!</span>
          <h2>
            Our Event <b>Sponsors</b>
          </h2>
          <div className="bar"></div>
        </div>
        <div className="row justify-content-center">
          {sponsors.map((sponsor) => (
            <div
              className="col-lg-2 col-md-4 col-sm-6 partner-center-content"
              key={sponsor.logoLink}
              style={{paddingTop: '10px', paddingBottom: '10px'}}
            >
              <div className="partner-item">
                {sponsor && sponsor.logoLink && (
                  <div>
                    {sponsor.sponsorLink ? (
                      <a
                        href={sponsor.sponsorLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={sponsor.logoLink} alt="Sponsor Logo" />
                        <img src={sponsor.logoLink} alt="Sponsor Logo" />{' '}
                        {/* Second img is there for the animation*/}
                      </a>
                    ) : (
                      <img src={sponsor.logoLink} alt="Sponsor Logo" />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Sponsors
