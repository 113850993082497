import React from 'react'
import OwlCarousel from 'react-owl-carousel3'
import lax from 'lax.js'
import LaxDiv from '../Shared/LaxDiv'
import agent from 'api/agent'
import { OrderBy } from 'models/common/orderBy'
import { withRouter } from 'react-router-dom'
import AwardCard from 'components/Awards/AwardCard'

const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplayHoverPause: true,
  autoplay: false,
  items: 1,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  center: true,
}

class EventSchedules extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false,
    )
    const pageSize = 40
    const today = new Date()
    const nextMonth = new Date(today)
    nextMonth.setMonth(today.getMonth() + 2)
    this.state = {
      data: {
        loading: true,
        rows: [],
        totalRows: 0,
        rowCount: 0,
        pageSize: pageSize,
        page: 0,
      },
      pageSize: pageSize,
      filterFromDate: today,
      filterToDate: null,
      isReloadRequested: false,
      chunkSize: 4,
      chunks: [],
    }

    lax.update(window.scrollY)
  }

  updateData = (key, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [key]: value,
      },
    }))
  }

  componentDidMount() {
    this.fetchData()
  }

  getChunks() {
    const { data, chunkSize } = this.state
    const chunks = []
    for (let i = 0; i < data?.rows.length; i += chunkSize) {
      const chunk = data?.rows.slice(i, i + chunkSize)
      chunks.push(chunk)
    }
    this.setState({ chunks })
  }

  fetchData = () => {
    const { data, filterFromDate, filterToDate } = this.state

    if (!data.loading) {
      this.updateData('loading', true)
    }

    agent.BusinessEvent.getFilteredPage(
      data.page,
      data.pageSize,
      filterFromDate,
      filterToDate,
      null,
      OrderBy.OrderByDate,
    )
      .then((businessEvents) => {
        this.updateData('rowCount', businessEvents.allCount)

        setTimeout(() => {
          this.updateData('rows', businessEvents.page)
          this.updateData('loading', false)
          this.getChunks()
        }, 100)
      })
      .catch(() => {
        this.updateData('rows', 0)
        this.updateData('loading', false)
      })
  }

  render() {
    const { chunks } = this.state
    return (
      <section className="schedule-area bg-image ptb-120">
        {chunks?.length > 0 && (
          <div className="container">
            <div className="section-title">
              <span>Schedule Plan</span>
              <h2>
                Check out our <b>upcoming</b> <br /> events
              </h2>

              <LaxDiv text="Events" dataPreset="driftLeft" />

              <div className="bar"></div>
            </div>

            <div className="row">
              <OwlCarousel
                className="schedule-slides owl-carousel owl-theme"
                {...options}
              >
                {chunks?.map((chunk, i) => (
                  <div className="col-lg-12 col-md-12" key={i}>
                    <div className="schedule-slides-item">
                      {chunk.length > 0
                        ? chunk?.map((businessEvent) => (
                            <AwardCard
                              businessEvent={businessEvent}
                              key={businessEvent.id.toString()}
                            />
                          ))
                        : ''}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        )}
      </section>
    )
  }
}

export default withRouter(EventSchedules)
