import React from "react";
import { getFormattedDateAndTime } from "utils/dateUtils";

class MainBanner extends React.Component {
  render() {
    const { title, location, startDate, endDate, coverImageLink } = this.props;

    return (
      <div
        className="page-title-area item-bg1"
        style={{ backgroundImage: `url(${coverImageLink})` }}
      >
        <div className="container">
          <h1>{title}</h1>
          <h2
            style={{
              direction: "ltr",
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            <i className="icofont-compass"> </i>
            {location}
          </h2>
          {startDate != null && endDate != null && (
            <h2
              style={{
                color: "white",
                paddingTop: "10px",
                textAlign: "center",
              }}
            >
              <i className="icofont-wall-clock"> </i>
              {getFormattedDateAndTime(startDate, endDate)}
            </h2>
          )}
        </div>
      </div>
    );
  }
}

export default MainBanner;
