import { ILessDetailedBusinessEvent } from "models/businessEvent/lessDetailedBusinessEvent";
import React from "react";
import { getFormattedDateAndTime } from "utils/dateUtils";
import { useHistory } from "react-router-dom";

const HomeHeader: React.FC<{ businessEvent: ILessDetailedBusinessEvent }> = ({
  businessEvent,
}) => {
  const history = useHistory();
  const navigateTo = (path: string) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="main-banner item-bg2"
      style={{ backgroundImage: `url(${businessEvent.coverImageLink})` }}
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="main-banner-content banner-content-center">
              <h1>{businessEvent.name}</h1>
              <ul>
                <li>
                  <i className="icofont-compass"> </i> {businessEvent.location}
                </li>
                <li>
                  <i className="icofont-wall-clock"> </i>
                  {getFormattedDateAndTime(
                    businessEvent.startDate,
                    businessEvent.endDate
                  )}
                </li>
              </ul>
              <div className="button-box">
                <button
                  onClick={() => navigateTo(`/event/${businessEvent.id}`)}
                  className="btn btn-primary"
                  style={{ direction: "ltr", fontSize: 20 }}
                >
                  Open Event
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
