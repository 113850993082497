import { useState, useEffect } from 'react'
import axios from 'axios'

const useIPAddress = () => {
  const [ip, setIP] = useState('')

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json')
      setIP(response.data.ip)
    } catch (error) {}
  }

  useEffect(() => {
    fetchData()
  }, [])

  return ip
}

export default useIPAddress
