import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/events_and_awards_logo.png";

const Footer = () => {
  const history = useHistory();

  const handleNavigation = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  const [isSmallWindowWidth, setIsSmallWindowWidth] = useState(
    window.innerWidth <= 768
  );

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallWindowWidth = window.innerWidth <= 768;
      if (newIsSmallWindowWidth !== isSmallWindowWidth) {
        setIsSmallWindowWidth(newIsSmallWindowWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallWindowWidth]);

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          {!isSmallWindowWidth && (
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="copyright-area">
                <ul className="footer-list">
                  <li onClick={() => handleNavigation("/")}>Home</li>
                  <li onClick={() => handleNavigation("/events")}>Events</li>
                  <li onClick={() => handleNavigation("/references")}>
                    References
                  </li>
                  <li onClick={() => handleNavigation("/faq")}>FAQ</li>
                  <li onClick={() => handleNavigation("/contact")}>Contact</li>
                </ul>
              </div>
            </div>
          )}
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="copyright-area">
              <ul className="footer-list">
                <li>
                  <i className="icofont-phone"> </i>
                  <a href="tel:+36302075247">(+36) 30 207 5247</a>
                </li>
                <li>
                  <a href="mailto:peter@eventsandawards.com">
                    <i className="icofont-email"> </i>
                    peter@eventsandawards.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/peter_somogyi_events"
                    className="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icofont-instagram"> </i>
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/eventsandawards"
                    className="linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icofont-linkedin"> </i>
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/profile.php?id=61560382919073"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icofont-facebook"> </i>
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="copyright-area">
              <img
                style={{ maxWidth: "250px", cursor: "pointer" }}
                src={logo}
                alt="logo"
                onClick={() => handleNavigation("/")}
              />
            </div>
          </div>
          <div className="copyright-line"></div>

          <div
            className="copyright-area"
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ul className="footer-list" style={{ direction: "ltr" }}>
              <li>
                © COPYRIGHT 2024 BY EVENTS & AWARDS Ltd. -
                <a
                  href="https://eventsandawards.com/files/events_and_awards_privacy_policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingLeft: "5px" }}
                >
                  PRIVACY POLICY & COOKIE POLICY
                </a>
                {/* {" - "}
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingLeft: "5px" }}
                >
                  COOKIE POLICY
                </a> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
