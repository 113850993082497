export function formatDate(date: Date) {
  const isInValidDate = checkInvalidDate(date)
  if (isInValidDate) {
    return ''
  }
  // Format the date as 'YYYY Mon DD'
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date)

  return formattedDate
}

function checkInvalidDate(date: Date): boolean {
  return !(date instanceof Date) || isNaN(date.getTime())
}

export function formatTime(date: Date) {
  // Format the time as 'HH:mm'
  const isInValidDate = checkInvalidDate(date)
  if (isInValidDate) {
    return ''
  }

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(date)

  return formattedTime
}

export const getFormattedDateAndTime = (startDate: any, endDate: any) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  if (areDatesEqual(startDate, endDate)) {
    return `${formatDate(startDate)} ${formatTime(startDate)} - ${formatTime(
      endDate
    )}`;
  } else {
    return `${formatDate(startDate)} ${formatTime(startDate)} - ${formatDate(
      endDate
    )} ${formatTime(endDate)}`;
  }
};

export const areDatesEqual = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDay() === d2.getDay()
  );
};