import React, { useEffect } from "react";
import MainBanner from "../Home/MainBanner";
import About from "../Home/About";
import WhyUs from "../Home/WhyUs";
import GoTop from "../Shared/GoTop";
import Footer from "../Common/Footer";
import EventSchedules from "../Home/EventSchedules";
import lax from "lax.js";
import ApplyForm from "../Common/ApplyForm";
import ReferencesComponent from "components/References/ReferencesComponent";
import { useHistory } from "react-router-dom";

const HomeDefault = () => {
  const history = useHistory();

  useEffect(() => {
    lax.setup();

    const handleScroll = () => {
      lax.update(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll);

    lax.update(window.scrollY);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigation = (url) => {
    history.push(url);
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <MainBanner />
      <About />
      <WhyUs />
      <EventSchedules />
      <div style={{ paddingTop: "40px" }}>
        <div
          className="newsletter-area"
          style={{ direction: "ltr", paddingBottom: "42px" }}
        >
          <div className="container">
            <div className="row newsletter-wrap align-items-center">
              <div className="col-lg-10">
                <h4 style={{ marginBottom: 0 }}>
                  Contact Now and Get the Recognition You Deserve!
                </h4>
              </div>
              <div className="col-lg-2">
                <div className="newsletter-item" style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleNavigation("/contact")}
                  >
                    Contact
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReferencesComponent title="References" referencePage={false} />
      <ApplyForm />
      <Footer />

      {/* Back Top top */}
      <GoTop scrollStepInPx="50" delayInMs="16.66" />
    </React.Fragment>
  );
};

export default HomeDefault;
