import React from 'react'
import MainBanner from '../References/MainBanner'
import ReferencesComponent from '../References/ReferencesComponent'
import Footer from '../Common/Footer'

class References extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MainBanner />
        <ReferencesComponent referencePage={true} />
        <Footer />
      </React.Fragment>
    )
  }
}

export default References
